/* jshint ignore:start */

define('carlock-admin/config/environment', ['ember'], function(Ember) {
  return { 'default': {"modulePrefix":"carlock-admin","environment":"production","baseURL":"/","locationType":"auto","EmberENV":{"FEATURES":{}},"i18n":{"defaultLocale":"en"},"ember-simple-auth":{"authenticationRoute":"c.login","routeAfterAuthentication":"c.profile","routeIfAlreadyAuthenticated":"c.profile","cookieName":"session"},"APP":{"type":"WEB_ADMIN","version":"1.71.1","name":"carlock-admin"},"hostURL":"https://manage.carlock.co","apiURL":"https://api.carlock.co/v2","adminURL":"https://admin.carlock.co/v1","storeURL":"https://store.carlock.co/v1","myCarLockURL":"https://my.carlock.co","cdnURL":"//s3-eu-west-1.amazonaws.com/manage.carlock.co","legacyApiURL":"https://api.carlock.co","managerV2URL":"https://manage-v2.carlock.co","development":false,"staging":false,"production":true,"something":"test","exportApplicationGlobal":false,"browserify":{"tests":true}}};
});

if (runningTests) {
  require("carlock-admin/tests/test-helper");
} else {
  require("carlock-admin/app")["default"].create({"type":"WEB_ADMIN","version":"1.71.1","name":"carlock-admin"});
}

/* jshint ignore:end */
